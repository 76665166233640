import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './Navigation';
import Book from './Book';
import Chapter from './Chapter';
import Login from './Login';

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  // Function to set the authenticated state, typically after successful login.
  const handleAuthentication = () => {
    setAuthenticated(true);
  };

  // Check for authentication in localStorage when the app loads
  useEffect(() => {
    const isAuth = localStorage.getItem('authenticated');
    if (isAuth === 'true') {
      setAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          {authenticated ? (
            <>
              <Route path="/Books/:book" element={<Book />} />
              <Route path="/Books/:book/:chapterNumber" element={<Chapter />} />
              <Route path="/" element={<Navigation />} />
            </>
          ) : (
            <Route
              path="/"
              element={<Login onAuthentication={handleAuthentication} />}
            />
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
