import React from 'react';
import { Link, useParams } from 'react-router-dom';

function Book() {
  const { book } = useParams();
  const maxChaptersToDisplay = 1000; // Set your desired maximum number of chapters to display

  const chapters = [];

  for (let chapterNumber = 0; chapterNumber <= maxChaptersToDisplay; chapterNumber++) {
    const chapterFileName = `Chapter-${chapterNumber}.html`;
    chapters.push({
      number: chapterNumber,
      filename: chapterFileName,
    });
  }

  return (
    <div>
      <h2>{book} - Chapters</h2>
      <ul>
        {chapters.map((chapter) => (
          <li key={chapter.number}>
            <Link to={`/Books/${book}/${chapter.filename}`}>
              Chapter {chapter.number}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Book;
