// src/components/Login.js

import React, { useState } from 'react';

function Login({ onAuthentication }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    if (username === 'Dango' && password === '0831') {
      // Store an authentication flag in localStorage or sessionStorage
      localStorage.setItem('authenticated', 'true');
      // Call the onAuthentication function to set the authenticated state
      onAuthentication();
    } else {
      console.log('Login failed');
    }
  };

  return (
    <div className='login-container'>
      <div className='loginDiv'>
        <h1>Dango's Library Login</h1>
        <input className='form-control'
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input className='form-control'
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className='btn btn-primary' onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
}

export default Login;
