import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
  return (
    <div>
      <h2>Select a Book</h2>
      <ul>
        <li>
            <Link to="/Books/Azarinth_Healer">Azarinth Healer</Link>
        </li>
        {/* Add more books as needed */}
      </ul>
    </div>
  );
}

export default Navigation;
