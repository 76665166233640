import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function Chapter() {
  const { book, chapterNumber } = useParams();
  const [loadedHTML, setLoadedHTML] = useState('');
  const chapterNumberOnly = chapterNumber.replace(/\D/g, '');

  useEffect(() => {
    // Construct the path to the chapter HTML file
    const chapterFilePath = `/assets/Books/${book}/Chapter-${chapterNumberOnly}.html`;

    async function fetchChapterHTML() {
      try {
        const response = await fetch(chapterFilePath);
        if (response.ok) {
          const html = await response.text();
          setLoadedHTML(html);
        } else {
          // Handle error when the file is not found or there's an issue with the fetch request.
          console.error('Error loading chapter HTML');
        }
      } catch (error) {
        // Handle any other errors that may occur during the fetch.
        console.error('Error loading chapter HTML:', error);
      }
    }

    fetchChapterHTML();
  }, [book, chapterNumberOnly]);

  return (
    <div>
        <a className='nextChapBtn btn btn-primary' href={`/`}>
          Home
        </a>

      <div className='chapContentBox'>
        <div dangerouslySetInnerHTML={{ __html: loadedHTML }} />
      </div>
      
      <div className='chapBtnBox'>
      {parseInt(chapterNumberOnly) > 0 && (
          <a className='prevChapBtn btn btn-primary' href={`/Books/${book}/Chapter-${parseInt(chapterNumberOnly) - 1}.html`}>
            Previous
          </a>
        )}
        <a className='nextChapBtn btn btn-primary' href={`/Books/${book}/Chapter-${parseInt(chapterNumberOnly) + 1}.html`}>
          Next
        </a>
      </div>
    </div>
  );
}

export default Chapter;
